#editor,
.editor {
  background: white;
  color: black;
  background-clip: padding-box;
  border-radius: 0;
  padding: 0px 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

#editor s, .editor s {
  text-decoration-color: #6F7C9F;
}

#editor > * > *, .editor > * > * {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror {
  padding-left: 16px;
  padding-right: 16px;
  margin: 14px 0 0 0;
}

.ProseMirror-content {
  max-height: 100%;
  overflow: auto;
}

/* LISTS */


li {
  margin-top: 10px;
}

li:last-of-type {
  margin-bottom: 10px;
}

li p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* TO DO LISTS */
ul[data-type="todo_list"] {
  padding-left: 16px;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

li[data-type="todo_item"]:last-of-type {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.todo-checkbox {
  box-sizing: border-box;
  border: 2px solid #BFBFBF;
  height: 20px;
  width: 20px;
  margin-right: 14px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.3em;
}

.todo-checkbox-checked {
  background-color: #2FDBA1;
  border-color: #2FDBA1;
  background-image: url("tick.svg");
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin: 0;
}

li[data-done="true"] {
  text-decoration: line-through;
  text-decoration-color: #6f7c9f;
}

li[data-done="true"] .todo-checkbox-unchecked {
  display: none;
}

li[data-done="false"] .todo-checkbox-checked {
  display: none;
}

li[data-done="true"] li[data-done="false"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] li[data-done="true"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] {
  text-decoration: none;
}
/* TABLE */
.ProseMirror table {
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.ProseMirror .tableWrapper {
  margin: 1em 0;
}

/* TABLE ELEMENTS */
.ProseMirror th,
.ProseMirror td {
  min-width: 1em;
  border: 1px solid #ddd;
  text-align: center;

  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  overflow: auto;
}

/* FIX TABLE IMAGES */
.ProseMirror table > tbody > tr > td > p > img {
  max-width: 100%;
}

.ProseMirror table > tbody > tr > th > p > img {
  max-width: 100%;
}

/* FIX TABLE TABLES */
.ProseMirror table > tbody > tr > td > div.tableWrapper {
  width: 100% !important;
  margin: 0;
}

.ProseMirror table > tbody > tr > td > div.tableWrapper > table {
  width: 100% !important;
  overflow: hidden !important;
}

.ProseMirror th {
  font-weight: bold;
  text-align: left;
}

.ProseMirror .column-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  z-index: 20;
  background-color: #8a5df8;
  opacity: 0.5;
  pointer-events: none;
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 15px -4px rgba(31, 73, 125, 0.8);
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.emoji-suggesting {
  color: orange;
  padding: 1px;
  background-color: rgba(255, 166, 0, 0.075);
  border-radius: 4px;
  overflow: hide;
}

.mentions-suggesting, .resolved-mention {
  color: blue;
  padding: 1px;
  background-color: rgba(0, 0, 255, 0.096);
  border-radius: 4px;
  overflow: hide;
}

.resolved-mention::before {
  content: "@";
}

.resolved-timestamp {
  color: #313131;
  padding: 1px;
  background-color:rgba(37, 37, 37, 0.096);
  border-radius: 4px;
  overflow: hide;
}

.command-suggesting {
  color: blueviolet;
  padding: 1px;
  background-color: rgba(137, 43, 226, 0.199);
  border-radius: 4px;
  overflow: hide;
}

ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

/* READONLY STYLES */

.readonly-grey {
  filter: blur(1px);
}