@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.ProseMirror-menubar {
  padding: 4px 4px 4px 4px;
}

.emoji-mart-preview {
  height: 48px !important;
}

.emoji-mart-category-label span {
  font-size: 12px;
  font-family: Inter;
}

.emoji-mart {
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.emoji-mart-search > input {
  font-family: Inter;
}

.emoji-mart-category li {
  cursor: pointer;
}

.emoji-mart-category button {
  cursor: pointer;
}

.emoji-mart-emoji span {
  cursor: pointer !important;
}

.emoji-mart-category-label * {
  cursor: default !important;
}
#editor,
.editor {
  background: white;
  color: black;
  background-clip: padding-box;
  border-radius: 0;
  padding: 0px 0;
  font-family: Inter, sans-serif;
  font-size: 14px;
}

#editor s, .editor s {
  -webkit-text-decoration-color: #6F7C9F;
          text-decoration-color: #6F7C9F;
}

#editor > * > *, .editor > * > * {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ProseMirror-focused {
  outline: none;
}

.ProseMirror {
  padding-left: 16px;
  padding-right: 16px;
  margin: 14px 0 0 0;
}

.ProseMirror-content {
  max-height: 100%;
  overflow: auto;
}

/* LISTS */


li {
  margin-top: 10px;
}

li:last-of-type {
  margin-bottom: 10px;
}

li p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* TO DO LISTS */
ul[data-type="todo_list"] {
  padding-left: 16px;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

li[data-type="todo_item"]:last-of-type {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.todo-checkbox {
  box-sizing: border-box;
  border: 2px solid #BFBFBF;
  height: 20px;
  width: 20px;
  margin-right: 14px;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.3em;
}

.todo-checkbox-checked {
  background-color: #2FDBA1;
  border-color: #2FDBA1;
  background-image: url(/static/media/tick.fe5446c7.svg);
  background-repeat: no-repeat;
  background-size: 65%;
  background-position: center;
}

.todo-content {
  flex: 1 1;
}

.todo-content p {
  margin: 0;
}

li[data-done="true"] {
  text-decoration: line-through;
  -webkit-text-decoration-color: #6f7c9f;
          text-decoration-color: #6f7c9f;
}

li[data-done="true"] .todo-checkbox-unchecked {
  display: none;
}

li[data-done="false"] .todo-checkbox-checked {
  display: none;
}

li[data-done="true"] li[data-done="false"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] li[data-done="true"] .todo-checkbox-checked {
  display: inline-block;
}

li[data-done="false"] {
  text-decoration: none;
}
/* TABLE */
.ProseMirror table {
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
}

.ProseMirror .tableWrapper {
  margin: 1em 0;
}

/* TABLE ELEMENTS */
.ProseMirror th,
.ProseMirror td {
  min-width: 1em;
  border: 1px solid #ddd;
  text-align: center;

  vertical-align: top;
  box-sizing: border-box;
  position: relative;

  overflow: auto;
}

/* FIX TABLE IMAGES */
.ProseMirror table > tbody > tr > td > p > img {
  max-width: 100%;
}

.ProseMirror table > tbody > tr > th > p > img {
  max-width: 100%;
}

/* FIX TABLE TABLES */
.ProseMirror table > tbody > tr > td > div.tableWrapper {
  width: 100% !important;
  margin: 0;
}

.ProseMirror table > tbody > tr > td > div.tableWrapper > table {
  width: 100% !important;
  overflow: hidden !important;
}

.ProseMirror th {
  font-weight: bold;
  text-align: left;
}

.ProseMirror .column-resize-handle {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  z-index: 20;
  background-color: #8a5df8;
  opacity: 0.5;
  pointer-events: none;
  box-shadow: 0 9px 0px 0px white, 0 -9px 0px 0px white,
    12px 0 15px -4px rgba(31, 73, 125, 0.8),
    -12px 0 15px -4px rgba(31, 73, 125, 0.8);
}

.ProseMirror.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.ProseMirror .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}
.emoji-suggesting {
  color: orange;
  padding: 1px;
  background-color: rgba(255, 166, 0, 0.075);
  border-radius: 4px;
  overflow: hide;
}

.mentions-suggesting, .resolved-mention {
  color: blue;
  padding: 1px;
  background-color: rgba(0, 0, 255, 0.096);
  border-radius: 4px;
  overflow: hide;
}

.resolved-mention::before {
  content: "@";
}

.resolved-timestamp {
  color: #313131;
  padding: 1px;
  background-color:rgba(37, 37, 37, 0.096);
  border-radius: 4px;
  overflow: hide;
}

.command-suggesting {
  color: blueviolet;
  padding: 1px;
  background-color: rgba(137, 43, 226, 0.199);
  border-radius: 4px;
  overflow: hide;
}

ol ol {
  list-style-type: lower-alpha;
}

ol ol ol {
  list-style-type: lower-roman;
}

/* READONLY STYLES */

.readonly-grey {
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
#editor img, .editor img {
  max-width: 100%;
  max-height: 226px;
  border-radius: 6px;
}
.nice-dates-navigation {
  align-items: center;
  color: #333;
  display: flex;
  justify-content: space-between;
  position: relative;
  text-align: center;
  text-transform: capitalize;
  width: 168px;
  margin: 18px 80px 12px 80px;
}
.nice-dates-navigation_current {
  flex-grow: 1;
  font-family: Inter;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.nice-dates-navigation_previous, .nice-dates-navigation_next {
  cursor: pointer;
  height: 0;
  padding-bottom: calc(100% / 7);
  position: relative;
  width: calc(100% / 7);
}
.nice-dates-navigation_previous:before, .nice-dates-navigation_next:before {
  border-right: 2px solid #058FEF;
  border-top: 2px solid #058FEF;
  box-sizing: border-box;
  content: "";
  display: block;
  height: 10px;
  left: 50%;
  margin-left: -7px;
  margin-top: -7px;
  position: absolute;
  top: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: 200ms border-color;
  width: 10px;
  border-color: #058FEF;
}
.nice-dates-navigation_previous:hover:before, .nice-dates-navigation_next:hover:before {
  border-color: #058FEF;
}
.nice-dates-navigation_previous.-disabled, .nice-dates-navigation_next.-disabled {
  cursor: default;
  pointer-events: none;
}
.nice-dates-navigation_previous.-disabled:before, .nice-dates-navigation_next.-disabled:before {
  border-color: #ddd;
}
.nice-dates-navigation_previous:before {
  -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
}
.nice-dates-week-header {
  display: flex;
  width: 280px;
  margin-top: 12px !important;
  margin-bottom: 17px !important;
}
.nice-dates-week-header_day {
  color: #8792AF;
  text-transform: uppercase !important;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  width: calc(100% / 7);
}
.nice-dates-grid {
  box-sizing: content-box;
  overflow: hidden;
  width: 282px;
  height: 200px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.nice-dates-grid_container {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  display: flex;
  flex-wrap: wrap;
  left: 0;
  position: absolute;
  right: 0;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-property: none;
  will-change: transform;
  width: 320px;
  height: 300px;
}
.nice-dates-grid_container.-moving .nice-dates-day_date {
  transition: 300ms color;
}
.nice-dates-grid_container.-origin-bottom {
  top: auto;
  bottom: 0;
}
.nice-dates-grid_container.-origin-top {
  top: 0;
  bottom: auto;
  align-items: flex-start;
}
.nice-dates-grid_container.-transition {
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.nice-dates-day {
  border-top: 1px solid transparent;
  box-sizing: border-box;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  position: relative;
  text-align: center;
  margin: 5px 4px 5px 4px;
  width: calc(100% / 7);
  height: 32px !important;
  width: 32px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.nice-dates-day_date {
  transition: 150ms color;
  will-change: color;
  z-index: 3;
}
.nice-dates-day_month {
  display: none;
}
.nice-dates-day:before, .nice-dates-day:after {
  border-radius: 999px;
  bottom: 0;
  box-sizing: border-box;
  content: "";
  display: block;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
}
.nice-dates-day:before {
  background-color: #058FEF;
  z-index: 1;
}
.nice-dates-day:after {
  background: #058FEF;
  color: white;
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition-duration: 150ms;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  z-index: 2;
}
.nice-dates-day:not(.-disabled):hover:after {
  opacity: 1;
  color: white;
  -webkit-transform: scale(1);
          transform: scale(1);
}
@media (hover: none) {
  .nice-dates-day:after {
    content: none;
  }
  .nice-dates-day.-selected * {
    color: #fff;
  }
}
.nice-dates-day.-selected:before {
  opacity: 1;
}
.nice-dates-day.-selected:hover:after {
  background-color: #fff;
}
.nice-dates-day.-selected:not(:hover) *, .nice-dates-day.-selected.-disabled * {
  color: #fff;
}
.nice-dates-day.-selected.-selected-start:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  right: 0;
}
.nice-dates-day.-selected.-selected-end:before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  left: 0;
}
.nice-dates-day.-selected.-selected-middle:before {
  border-radius: 0;
  left: 0;
  right: 0;
}
.nice-dates-day.-selected.-selected-middle:nth-child(7n+1):before {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.nice-dates-day.-selected.-selected-middle:not(.-end):nth-child(7n):before {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.nice-dates-day.-today {
  font-weight: 600;
}
.nice-dates-day.-wide:before, .nice-dates-day.-wide:after {
  left: 12.5%;
  right: 12.5%;
}
.nice-dates-day.-outside {
  color: #BFBFBF;
}
.nice-dates-day.-disabled {
  cursor: default;
  pointer-events: none;
  color: #ddd;
}
.nice-dates-popover {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
  margin: 8px 0;
  max-width: 600px;
  position: absolute;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.4, 0.2, 0, 1.1);
  width: 100%;
  will-change: transform, opacity;
  z-index: 9;
}
.nice-dates-popover:not(.-open) {
  opacity: 0;
  pointer-events: none;
  -webkit-transform: perspective(1000px) rotateX(-15deg);
          transform: perspective(1000px) rotateX(-15deg);
}

.nice-dates-grid[style] {
  height: 210px !important;
}

.nice-dates-grid_container.-origin-top {
  align-content: flex-start;
  width: 300px;
}

.nice-dates-grid > span:hover {
  color: white;
}


